import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Widget } from '@simpl/dashboards-ng';
import { Criterion } from '@simpl/element-ng';
import { Observable } from 'rxjs';

import { Building, BuildingsService } from '../../shared/services/buildings.service';
import {
  EWS_EFFICIENCY_WIDGET_DESCRIPTOR,
  EWS_GENERAL_PURPOSE_WIDGET_DESCRIPTOR,
  EWS_IFRAME_WIDGET_DESCRIPTOR,
  EWS_LEMS_COLLECT_WIDGET_DESCRIPTOR,
  EWS_LEMS_CONTROL_DR_WIDGET_DESCRIPTOR,
  EWS_LEMS_CONTROL_WIDGET_DESCRIPTOR,
  EWS_PV_BATTERY_WIDGET_DESCRIPTOR,
  EWS_SIMULATION_COCKPIT_WIDGET_DESCRIPTOR,
  EWS_SIMULATION_DEMO_WIDGET_DESCRIPTOR,
  EWS_SITE_STATUS_WIDGET_DESCRIPTOR,
  EWS_WEATHER_FORECAST_WIDGET_DESCRIPTOR,
  EWS_WEATHER_WIDGET_DESCRIPTOR
} from '../../widgets/ews/widget-descriptors';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardPageComponent implements OnInit {

  title = 'EWS Dashboard';
  id: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;

  widgetCatalog: Widget[] = [
    EWS_WEATHER_WIDGET_DESCRIPTOR,
    EWS_WEATHER_FORECAST_WIDGET_DESCRIPTOR,
    EWS_PV_BATTERY_WIDGET_DESCRIPTOR,
    EWS_EFFICIENCY_WIDGET_DESCRIPTOR,
    EWS_IFRAME_WIDGET_DESCRIPTOR,
    EWS_LEMS_COLLECT_WIDGET_DESCRIPTOR,
    EWS_LEMS_CONTROL_DR_WIDGET_DESCRIPTOR,
    EWS_LEMS_CONTROL_WIDGET_DESCRIPTOR,
    EWS_SIMULATION_DEMO_WIDGET_DESCRIPTOR,
    EWS_SITE_STATUS_WIDGET_DESCRIPTOR,
    EWS_GENERAL_PURPOSE_WIDGET_DESCRIPTOR,
    EWS_SIMULATION_COCKPIT_WIDGET_DESCRIPTOR
  ];

  globalFilters$?: Observable<Criterion[] | undefined>;
  selectedBuilding?: Building;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly buildingService: BuildingsService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.id = id;

        this.buildingService.getBuildingsSub().subscribe({
          next: buildings => {
            this.setBuilding(buildings, id);
          },
          error: error => {
            console.error('DashboardComponent: cannot get data from BuildingService');
          }
        });
      }
    });
  }

  private setBuilding(buildings: Building[], id: string) {
    for (const building of buildings) {
      if (building.id === id) {
        this.title = 'EWS Dashboard for Building "' + building.name + '"';
        this.selectedBuilding = building;
      }
    }
  }
}
