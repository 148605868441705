<div *ngIf="deviceArr.length > 0; else noDevice" class="device-container">
    <div class="me-2">{{'DEVICE.AVAILABLE' | translate}}</div>
    <div *ngIf="deviceArr.length > 1; else singleDevice">
      <select  [(ngModel)]="gatewayId" class="d-flex" (change)="onDeviceChange($event)">
        <option *ngFor="let device of deviceArr; trackBy: trackByIndex" [value]="device?.attributes?.activationKey">{{device?.attributes?.activationKey}}</option>
      </select>
    </div>
</div>
<ng-template #noDevice>
  <div>{{'DEVICE.UNAVAILABLE' | translate}}</div>
</ng-template>
<ng-template #singleDevice>
  <div>{{deviceArr[0].attributes?.activationKey}}</div>
</ng-template>