import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SolaceService } from '../../shared/services/solace.service';

@Component({
  selector: 'app-dr-demo',
  templateUrl: './dr-demo.component.html',
  styleUrls: ['./dr-demo.component.scss']
})
export class DrDemoComponent {
  form = this.buildForm();
  labelWidth = 140;
  trackByIndex = (index: number) => index;

  constructor(private solaceService: SolaceService) {}

  onConnectButtonClicked() {
    if (!this.solaceService.isConnected) {
      const url = this.form.value.url!;
      const vpn = this.form.value.vpn!;
      this.solaceService.connectOAuth2(url, vpn);
    }
  }

  onDisconnectButtonClicked() {
    if (this.solaceService.isConnected) {
      this.solaceService.disconnect();
    }
  }

  isConnected(): boolean {
    return this.solaceService.isConnected;
  }

  private buildForm() {
    return new FormGroup(
      {
        url: new FormControl('wss://mr-connection-kh6rtcfogcl.messaging.solace.cloud:443', [Validators.required, Validators.minLength(5)]),
        vpn: new FormControl('eaas-measurement', [Validators.required, Validators.minLength(5)])
        // user: new FormControl('', [Validators.required, Validators.minLength(5)]),
        // password: new FormControl('', [Validators.required, Validators.minLength(5)])
      }
    );
  }
}
