import {
  ChartDefinition,
  TrendConfigurationApi,
  TrendSeries,
  TrendSeriesDetails
} from '@simpl/trendviewer-ng';
import { Observable, Subject } from 'rxjs';

export class DemoTrendconfigApiService implements TrendConfigurationApi {
  readonly chartSeries = new Subject<TrendSeries>();
  readonly addDataPointRequest = new Subject();
  readonly locationSelection = new Subject();
  readonly trendSeriesInformationDetailsSub = new Subject<any>();

  saveConfiguration(instanceId: string, chartDefinition: ChartDefinition, saveAs: boolean): Observable<boolean> {
    console.log('saveConfiguration', instanceId, chartDefinition, saveAs);
    return new Observable<boolean>(observer => {
      setTimeout(() => observer.next(true));
    });
  }

  discardConfiguration(instanceId: string, chartDefinition: ChartDefinition): void {
    console.log('discardConfiguration', instanceId, chartDefinition);
  }

  closeConfiguration(instanceId: string): void {
    console.log('closeConfiguration', instanceId);
  }

  addDataPoint(instanceId: string, gridIndex: number = 0): void {
    console.log('addDataPoint', instanceId, gridIndex);
    this.addDataPointRequest.next(gridIndex);
  }

  selectLocation(instanceId: string): void {
    console.log('selectLocation', instanceId);
    this.locationSelection.next(undefined);
  }

  okClicked(instanceId: string): Observable<boolean> {
    console.log('onClicked', instanceId);
    return new Observable<boolean>(observer => {
      // return this observable as 'false' for default Ok workflow & 'true' for custom flow
      setTimeout(() => observer.next(false));
    });
  }

  seriesPropertyChange(instanceId: string, trendSeries: TrendSeries): Observable<boolean> {
    console.log('seriesPropertyChange', instanceId, trendSeries);
    return new Observable<boolean>(observer => {
      setTimeout(() => {
        this.seriesPropChanged(trendSeries);
        observer.next(true);
      });
    });
  }

  seriesRemoved(instanceId: string, trendSeriesId: string): void {
    console.log('SeriesRemoved', instanceId, trendSeriesId);
  }

  private seriesPropChanged(trendSeries: TrendSeries) {
    this.chartSeries.next(trendSeries);
  }

  seriesInformation(instanceId: string, seriesDetails: TrendSeriesDetails): void {
    console.log('seriesInformation', instanceId, seriesDetails);
    this.trendSeriesInformationDetailsSub.next(seriesDetails);
  }
}
