import { WidgetConfig } from '@simpl/dashboards-ng';


export const WIDGETS_1: WidgetConfig[] = [{
  heading: 'EWS Efficiency',
  widgetId: '@simpl/dashboards-ng/ews/efficiency',
  minWidth: 3,
  width: 12,
  height: 5,
  expandable: true,
  payload: {},
  id: 'yjzu6ja',
  x: 0,
  y: 0
}, {
  heading: 'Weather Forecast',
  widgetId: '@simpl/dashboards-ng/line-chart',
  minWidth: 3,
  width: 8,
  height: 5,
  expandable: true,
  payload: {
    datasourceId: 'getLineChartData',
    config: { stacked: false, showLegend: true } },
  id: 'gm7hznn',
  x: 0,
  y: 5
}, {
  heading: 'EWS Weather',
  widgetId: '@simpl/dashboards-ng/ews/weather',
  minWidth: 3,
  width: 4,
  height: 5,
  expandable: true,
  payload: {},
  id: 'e8o5d8d',
  x: 8,
  y: 5
},
{
  heading: 'LEMS Collect',
  widgetId: '@simpl/dashboards-ng/ews/lems-collect',
  minWidth: 12,
  width: 12,
  height: 5,
  expandable: true,
  payload: {},
  id: '3avtu8v',
  x: 0,
  y: 5
}];

export const WIDGETS_2: WidgetConfig[] = [{
  heading: 'Weather Forecast',
  widgetId: '@simpl/dashboards-ng/line-chart',
  minWidth: 3,
  width: 8,
  height: 5,
  expandable: true,
  payload: {
    datasourceId: 'getLineChartData',
    config: { stacked: false, showLegend: true } },
  id: 'gm7xznn',
  x: 0,
  y: 5
}, {
  heading: 'EWS Weather',
  widgetId: '@simpl/dashboards-ng/ews/weather',
  minWidth: 3,
  width: 4,
  height: 5,
  expandable: true,
  payload: {},
  id: 'e8x5d8d',
  x: 8,
  y: 5
}, {
  heading: 'LEMS Collect',
  widgetId: '@simpl/dashboards-ng/ews/lems-collect',
  minWidth: 12,
  width: 12,
  height: 8,
  expandable: true,
  payload: {},
  id: '3avtu8v',
  x: 0,
  y: 5
}, {
  heading: 'LEMS Control - Demand Response',
  widgetId: '@simpl/dashboards-ng/ews/lems-control-dr',
  minWidth: 12,
  width: 12,
  height: 5,
  expandable: true,
  payload: {},
  id: '3avtu8r',
  x: 0,
  y: 5
}, {
  heading: 'LEMS Control',
  widgetId: '@simpl/dashboards-ng/ews/lems-control',
  minWidth: 12,
  width: 12,
  height: 6,
  expandable: true,
  payload: {},
  id: '3avtu85',
  x: 0,
  y: 5
}, {
  heading: 'Site Status',
  widgetId: '@simpl/dashboards-ng/ews/site-status',
  minWidth: 5,
  width: 5,
  height: 3,
  expandable: true,
  payload: {},
  id: '3avtu51',
  x: 0,
  y: 5
}];

export const WIDGETS_3: WidgetConfig[] = WIDGETS_2.concat(
  {
    heading: 'LEMS Control - Live Simulation Demo',
    widgetId: '@simpl/dashboards-ng/ews/simulation-demo',
    minWidth: 8,
    width: 8,
    height: 6,
    expandable: true,
    payload: {},
    id: '3avtu73',
    x: 4,
    y: 0
  },
  {
    heading: 'LEMS Control - Simulation Cockpit',
    widgetId: '@simpl/dashboards-ng/ews/simulation-cockpit',
    minWidth: 4,
    width: 4,
    height: 6,
    expandable: false,
    payload: {},
    id: '3avtu42',
    x: 0,
    y: 0
  }
);
