import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Config } from '../models/config.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configSub: BehaviorSubject<Config> = new BehaviorSubject({} as Config);

  setConfig(configObj: Config) {
    this.configSub.next(configObj);
  }

  getConfig() {
    return this.configSub;
  }

}