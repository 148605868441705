import { CommonModule } from '@angular/common';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NgZone } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
  AppConfigService,
  AuthenticationService,
  CognitoAuthenticationService,
  OAuthService,
  SiAddressModule,
  SiAODSModule,
  SiAppDataService,
  SiAppListService,
  SiemensIdAuthenticationMockService,
  SiLoginAuthModule,
  SiPermissionService,
  SiProfileService,
  SiSharedModule,
  SiSubscriptionsModule,
  SiUOModule
} from '@building-x/common-ui-ng';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SimplChartsNgModule } from '@simpl/charts-ng';
import { SimplDashboardsNgModule, SiWidgetStorage } from '@simpl/dashboards-ng';
import {
  SiActionDialogModule,
  SiLandingPageModule,
  SiMasterDetailContainerModule,
  SimplElementNgModule,
  SiNavbarModule,
  SiSidePanelModule
} from '@simpl/element-ng';
import { SiDashboardModule } from '@simpl/element-ng/dashboard';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { SimplTrendviewerNgModule, TrendConfigurationApi, TrendviewerApi } from '@simpl/trendviewer-ng';
import { SolaceMessageClientModule } from '@solace-community/angular-solace-message-client';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { environment } from '../environments/environment';
import { CustomRouteReuseStrategy } from './CustomRouteReuseStrategy';
import { AppWidgetStorage } from './app-widget-storage';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AuthGuard } from './guards/auth.guard';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { AppsComponent } from './pages/apps/apps.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard.component';
import { DemoTrendconfigApiService } from './pages/dr-dashboard/api/demo-trendconfig-api.service';
import { DemoTrendviewerApiService } from './pages/dr-dashboard/api/demo-trendviewer-api.service';
import { DrDashboardComponent } from './pages/dr-dashboard/dr-dashboard.component';
import { DrDemoComponent } from './pages/dr-demo/dr-demo.component';
import { LoginComponent } from './pages/login/login.component';
import { SingleSignOnComponent } from './pages/login/sso.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { UnauthorizedUIComponent } from './pages/unauthorized/unauthorized-ui.component';
import { UsersModule } from './pages/users/users.module';
import { DeviceSelectorComponent } from './shared/components/device-selector/device-selector.component';


export const createTranslateLoader = (http: HttpBackend): any => new MultiTranslateHttpLoader(http, [
  './assets/i18n/core/',
  './assets/i18n/dashboards-ng/',
  './assets/i18n/trendviewer-ng/',
  './assets/i18n/common-ui-ng/',
  './assets/i18n/common-ui-ng/no-translations/'
]);

export const authenticationFactory = (
  oAuthService: OAuthService,
  ngZone: NgZone,
  http: HttpClient
): any => {
  if (environment.test) {
    return new SiemensIdAuthenticationMockService(ngZone);
  } else if (environment.issuer.indexOf('amazoncognito') > 0) {
    return new CognitoAuthenticationService(
      oAuthService,
      environment.issuer,
      environment.profileUrl,
      http);
  } else {
    return new AuthenticationService(
      oAuthService,
      environment.issuer,
      environment.msIssuer,
      environment.profileUrl
    );
  }
};

@NgModule({
  declarations: [
    AboutComponent,
    AppComponent,
    AppsComponent,
    DashboardPageComponent,
    DeviceSelectorComponent,
    DrDashboardComponent,
    DrDemoComponent,
    LoginComponent,
    LogoutComponent,
    MainComponent,
    OnboardingComponent,
    OrganizationsComponent,
    SingleSignOnComponent,
    UnauthorizedUIComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SiActionDialogModule,
    SiDashboardModule,
    SiLandingPageModule,
    SiLoginAuthModule,
    SiMasterDetailContainerModule,
    SimplChartsNgModule,
    SimplElementNgModule,
    SiNavbarModule,
    SiSharedModule,
    SiSidePanelModule,
    SiAddressModule.forRoot({
      aMapApiKey: '30775936ba317c91d1277a6430507442',
      googleConfig: {
        apiKey: environment.googleMapsApiKey,
        libraries: ['places'],
        language: localStorage?.lang || 'template',
        channel: environment.googleAPIChannel
      },
      isChinaDeployment: false
    }),
    SiAODSModule.forRoot({
      apiUrl: `${environment.aodsUrl}`
    }),
    SiUOModule.forRoot({
      apiUrl: environment.userApiUrlV2,
      invitationCallbackUrl: environment.invitationCallbackUrl,
      product: environment.product,
      noGoogleApi: environment.test
    }),
    SiSubscriptionsModule.forRoot({
      apiUrl: environment.apiUrlV2,
      invitationCallbackUrl: environment.invitationCallbackUrl,
      product: environment.product,
      noGoogleApi: environment.test
    }),

    RouterModule.forRoot(routes, {
      useHash: environment.useHash
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend]
      }
    }),
    SiTranslateNgxTModule,
    UsersModule,
    SimplDashboardsNgModule.forRoot({
      config: {},
      dashboardApi: {
        provide: SiWidgetStorage,
        useClass: AppWidgetStorage
      }
    }),
    SimplTrendviewerNgModule.forRoot({
      trendviewerApi: { provide: TrendviewerApi, useClass: DemoTrendviewerApiService },
      trendconfigurationApi: {
        provide: TrendConfigurationApi,
        useExisting: DemoTrendconfigApiService
      }
    }),
    SolaceMessageClientModule.forRoot()
  ],
  providers: [
    AppConfigService,
    AuthGuard,
    {
      provide: AuthenticationService,
      useFactory: authenticationFactory,
      // eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays
      deps: [OAuthService, NgZone, HttpClient]
    },
    DemoTrendconfigApiService,
    SiAppDataService,
    SiAppListService,
    SiPermissionService,
    SiProfileService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ],
  exports: [SimplElementNgModule, TranslateModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
