import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SiCookiesService } from '@building-x/common-ui-ng';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class Localization {

  constructor(
    private readonly translate: TranslateService,
    private readonly cookieService: SiCookiesService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.cookieService.setDomain();
    this.translate.setDefaultLang('template');
    this.translate.onLangChange.subscribe(event => this.storeLang(event));
  }

  detectLanguage() {
    const browserLanguage = this.translate.getBrowserLang();
    const localLanguage = this.loadLang();
    const lang = localLanguage || browserLanguage;

    if (lang && this.isTranslationFilePresent(lang)) {
      this.translate.use(lang);
    } else {
      this.translate.use(this.translate.getDefaultLang());
    }
    this.updateHTMLLang(lang);
  }

  setUserLanguage(language: string) {
    if (this.isTranslationFilePresent(language)) {
      this.translate.use(language);
    }
    this.updateHTMLLang(language);
  }

  updateHTMLLang(lang) {
    this.document.documentElement.lang = lang;
  }

  isTranslationFilePresent(lang): boolean {
    try {
      // eslint-disable-next-line
      const translationFile = require(`../../../assets/i18n/core/${lang}.json`);
      return !!translationFile;
    } catch (error) {
      return false;
    }
  }

  storeLang(event: LangChangeEvent) {
    const cookieExpiry = new Date();
    cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    this.cookieService.setCookie('lang', event.lang, '', cookieExpiry.toUTCString());

    // not working for ews-dev.siemens.cloud, why?
    //
    // let retryCount = 0;
    // const cookiePolicyRetry = setInterval(() => {
    //   retryCount++;
    //   if (this.cookiePolicy.permitted(OneTrustCookieTypes.Required) || retryCount === 6) {
    //     clearInterval(cookiePolicyRetry);
    //     if (this.cookiePolicy.permitted(OneTrustCookieTypes.Required)) {
    //       const cookieExpiry = new Date();
    //       cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    //       if (event.lang !== 'template') {
    //         this.cookieService.setCookie('lang', event.lang, '', cookieExpiry.toUTCString());
    //       }
    //     }
    //   }
    // }, 500);
  }

  loadLang(): string {
    return this.cookieService.getCookie('lang') && this.cookieService.getCookie('lang') !== 'undefined' ?
      this.cookieService.getCookie('lang') : '';
  }

  getTranslate(): TranslateService {
    return this.translate;
  }
}
