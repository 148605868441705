import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { TranslateService } from '@ngx-translate/core';
import { DeleteConfirmationDialogResult, SiActionDialogService, SiToastNotificationService } from '@simpl/element-ng';
import { DeviceService } from 'src/app/shared/services/device.service';

import { Building, BuildingsService } from '../../shared/services/buildings.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  buildings: Building[] = [];
  onboardingDisabled = true;
  deviceList: any[] = [];
  deviceNames: any[] = [];

  trackByIndex = (index: number) => index;

  form: FormGroup = this.formBuilder.nonNullable.group({
    activationKey: ['', [Validators.required]],
    deviceName: ['', [Validators.required, Validators.minLength(5)]],
    site: ['', [Validators.required]]
  });

  deleteForm: FormGroup = this.formBuilder.nonNullable.group({
    site: ['', [Validators.required]],
    deviceName: ['', [Validators.required]],
    activationKey: ['', [Validators.required]]
  });

  constructor(
    private route: ActivatedRoute,
    private siModal: SiActionDialogService,
    private buildingService: BuildingsService,
    private deviceService: DeviceService,
    private toastNotificationService: SiToastNotificationService,
    private translateService: TranslateService,
    private readonly siemensIdService: AuthenticationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const activationKey = params.get('key');
      if (activationKey) {
        this.form.controls.activationKey.setValue(activationKey);
      }
    });

    this.buildingService.getBuildingsSub().subscribe({
      next: buildings => {
        this.buildings = buildings;
        if (this.buildings && this.buildings.length > 0) {
          this.onboardingDisabled = false;
        } else {
          this.onboardingDisabled = true;
          this.buildings = [];
        }
      },
      error: error => {
        console.error('OnboardingComponent: cannot get data from BuildingService');
      }
    });
  }

  public onboardDevice() {
    this.onboardingDisabled = true;
    const selectedSite = this.getSelectedSiteDetails(this.onboardingSite);
    this.deviceService.onboardDevice(this.onboardingDeviceName, this.onboardingActivationKey,
      selectedSite?.partition, selectedSite?.id, this.siemensIdService.accessToken).subscribe(
      response => {
        if (response.status === 201) {
          this.toastNotificationService.showToastNotification({
            state: 'success',
            title: 'Success',
            message: this.translateService.instant('ONBOARDING.SUCCESS')
          });
        } else if (response.status === 200) {
          this.toastNotificationService.showToastNotification({
            state: 'info',
            title: 'Success',
            message: this.translateService.instant('ONBOARDING.DUPLICATE')
          });
        }
        this.onboardingDisabled = false;
      },
      error => {
        console.log(error);
        this.toastNotificationService.showToastNotification({
          state: 'danger',
          title: 'Failure',
          message: (error?.errors) ? (error?.errors[0]?.detail) : this.translateService.instant('ONBOARDING.ERROR.DEFAULT')
        });
        this.onboardingDisabled = false;
      }
    );
  }

  public getSelectedSiteDetails(locationId: string) {
    const selectedSite = this.buildings.find(building => building.id === locationId);
    return selectedSite;
  }

  get onboardingActivationKey() {
    return this.form.controls.activationKey.value;
  }

  get onboardingSite() {
    return this.form.controls.site.value;
  }

  get onboardingDeviceName() {
    return this.form.controls.deviceName.value;
  }

  public getDeviceDetails() {
    this.resetDeleteFormFields();
    const selectedSite = this.getSelectedSiteDetails(this.deleteSite);

    this.deviceService.getDeviceDetails(selectedSite?.partition, selectedSite?.id).subscribe(
      response => {
        this.deviceList = response;
        this.getDeviceName(this.deviceList);
      },
      error => {
        console.log(error);
      }
    );
  }

  public getDeviceName(device: any) {
    this.deviceNames = device.map(deviceDetails => deviceDetails?.attributes?.profile?.name);
  }

  public getActivationKey() {
    const activationKey = this.deviceList.find(
      device => device?.attributes?.profile?.name === this.deleteDeviceName
    )?.attributes?.activationKey;
    this.deleteForm.controls.activationKey.setValue(activationKey);
  }

  public deleteConfirmation() {
    this.siModal
      .showDeleteConfirmationDialog(
        'DELETE.CONFIRM_MSG',
        'DELETE.CONFIRM_HEAD',
        undefined,
        undefined,
        { device: this.deleteActivationKey }
      )
      .subscribe(result => {
        switch (result) {
          case DeleteConfirmationDialogResult.Delete:
            this.deleteDevice();
            break;
          case DeleteConfirmationDialogResult.Cancel:
            break;
        }
      });
  }

  private deleteDevice() {
    this.deviceService.deleteDevice(this.deleteActivationKey, this.siemensIdService.accessToken).subscribe(
      response => {
        this.toastNotificationService.showToastNotification({
          state: 'success',
          title: 'Success',
          message: this.translateService.instant('DELETE.SUCCESS')
        });
      },
      error => {
        console.log(error);
        this.toastNotificationService.showToastNotification({
          state: 'danger',
          title: 'Failure',
          message: (error?.errors) ? (error?.errors[0]?.detail) : this.translateService.instant('DELETE.ERROR')
        });
      }
    );
  }

  private resetDeleteFormFields() {
    this.deleteForm.controls.activationKey.reset();
    this.deviceNames = [];
    this.deviceList = [];
    this.deleteForm.controls.deviceName.reset();
  }

  get deleteActivationKey() {
    return this.deleteForm.controls.activationKey.value;
  }

  get deleteDeviceName() {
    return this.deleteForm.controls.deviceName.value;
  }

  get deleteSite() {
    return this.deleteForm.controls.site.value;
  }
}
