
export const environment = {
  production: true,
  apiMode: 'cloud',
  test: false,
  useHash: true,
  googleMapsApiKey: 'AIzaSyCP1hxTyunIZXjNB8oywKnniZ3-0aMbQAE',
  webappVerticalApiUrl: 'https://product-dev.webapp.horizonint.cloud/api/v1/me/apps',
  apiUrl: 'https://prodint.user.horizonint.cloud/api/v1',
  licenseApiUrl: 'https://product-int.subscription.horizonint.cloud/api/v1',
  apiUrlV2: 'https://product-int.subscription.horizonint.cloud/api/v2',
  userApiUrlV2: 'https://prodint.um.user.horizonint.cloud/api/v2',
  aodsUrl: 'https://product-int.aods.horizonint.cloud/api/v2',
  invitationCallbackUrl: 'http://localhost:4200/main/invitation',
  clientId: 'wmq4CxwvaBZmMxlDVjWdmMfDxSXa5VfX',
  audience: 'https://horizonint.cloud',
  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  redirectUrl: 'https://ews-dev.siemens.cloud',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  profileUrl: 'https://uss.login-dev.siemens.com/userprofile',
  product: 'EWS Dashboard',
  siemensIdBaseUrl: 'https://login-qa.siemens.com',
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js',
  appswitcherData: `W3siaHJlZiI6Imh0dHBzOi8vc3VwcG9ydC5hcHBzLmhvcml6b25kZXYuY2xvdWQvIy9zc28iL
  CJpY29uIjoibWFyZW5nby1uby1hY2Nlc3MgZWxlbWVudC13b3JraW5nIiwiaWQiOiJhcHAuYmFja09mZmljZSIsInRp
  dGxlIjoiU3VwcG9ydCJ9LHsiaHJlZiI6Imh0dHBzOi8vYWNjb3VudC5hcHBzLmhvcml6b25kZXYuY2xvdWQvIy9zc28
  iLCJpY29uIjoibWFyZW5nby1hY2NvdW50IGVsZW1lbnQtYWNjb3VudCIsImlkIjoiYXBwLmFjY291bnRNZ3IiLCJ0aX
  RsZSI6IkFjY291bnRzIn0seyJocmVmIjoiaHR0cHM6Ly9hc3NldHMuYXBwcy5ob3Jpem9uZGV2LmNsb3VkLyMvc3NvI
  iwiaWNvbiI6Im1hcmVuZ28tcGFuZWwgZWxlbWVudC1jYW52YXMiLCJpZCI6ImFwcC5hc3NldE1nciIsInRpdGxlIjoi
  RGV2aWNlcyJ9LHsiaHJlZiI6Imh0dHBzOi8vZWRnZS5hcHBzLmhvcml6b25kZXYuY2xvdWQvIy9zc28iLCJpY29uIjo
  ibWFyZW5nby1wYW5lbCBlbGVtZW50LXByZXNlbnRhdGlvbiIsImlkIjoiYXBwLmVkZ2VBcHBNZ3IiLCJ0aXRsZSI6Ik
  VkZ2UifSx7ImhyZWYiOiJodHRwczovL29wZXJhdG9yLmFwcHMuaG9yaXpvbmRldi5jbG91ZC8jL3NzbyIsImljb24iO
  iJtYXJlbmdvLWJ1aWxkaW5nLWF1dG9tYXRpb24gZWxlbWVudC1wcm9qZWN0IiwiaWQiOiJhcHAuYnVpbGRpbmdPcGVy
  YXRvciIsInRpdGxlIjoiT3BlcmF0b3IifSx7ImhyZWYiOiJodHRwczovL2FwcC1ib2ljLmhvcml6b25kZXYuY2xvdWQ
  vc3NvIiwiaWNvbiI6Im1hcmVuZ28tYnVpbGRpbmctYXV0b21hdGlvbiBlbGVtZW50LXByb2plY3QiLCJpZCI6ImFwcC
  5ib2ljIiwidGl0bGUiOiJCdWlsZGluZyBPcGVyYXRvciJ9LHsiaHJlZiI6Imh0dHBzOi8vY2VyYmVydXNwb3J0YWwua
  G9yaXpvbmRldi5jbG91ZC9zc28iLCJpY29uIjoibWFyZW5nby1idWlsZGluZy1hdXRvbWF0aW9uIGVsZW1lbnQtcHJv
  amVjdCIsImlkIjoiYXBwLmNlcmJlcnVzIiwidGl0bGUiOiJDZXJiZXJ1cyBQb3J0YWwifSx7ImhyZWYiOiJodHRwczo
  vL3d3dy5wcmV2aWV3LmNsaW1hdGl4aWMuY29tL0FjY291bnQvRXh0ZXJuYWxMb2dpbj9wcm92aWRlcj1zaWVtZW5zaW
  QiLCJpY29uIjoibWFyZW5nby1idWlsZGluZy1hdXRvbWF0aW9uIGVsZW1lbnQtcHJvamVjdCIsImlkIjoiYXBwLmNsa
  W1hdGl4aWMiLCJ0aXRsZSI6IkNsaW1hdGl4IElDIn0seyJocmVmIjoiaHR0cHM6Ly9zaW50ZXNvcG9ydGFsLmhvcml6
  b25kZXYuY2xvdWQvc3NvIiwiaWNvbiI6Im1hcmVuZ28tYnVpbGRpbmctYXV0b21hdGlvbiBlbGVtZW50LXByb2plY3Q
  iLCJpZCI6ImFwcC5zaW50ZXNvIiwidGl0bGUiOiJTaW50ZXNvIFBvcnRhbCJ9LHsiaHJlZiI6Imh0dHBzOi8vZGVzaW
  dvZmlyZXBvcnRhbC5ob3Jpem9uZGV2LmNsb3VkL3NzbyIsImljb24iOiJtYXJlbmdvLWJ1aWxkaW5nLWF1dG9tYXRpb
  24gZWxlbWVudC1wcm9qZWN0IiwiaWQiOiJhcHAuZGVzaWdvZmlyZSIsInRpdGxlIjoiRGVzaWdvIEZpcmUgUG9ydGFs
  In0seyJocmVmIjoiaHR0cHM6Ly9hbGFybS5hcHBzLmhvcml6b25kZXYuY2xvdWQvIy9zc28iLCJpY29uIjoibWFyZW5
  nby1hbGFybS1vdXRsaW5lIGVsZW1lbnQtYWxhcm0iLCJpZCI6ImFwcC5kYXNoYm9hcmQiLCJ0aXRsZSI6IkFsYXJtIE
  Rhc2hib2FyZCJ9LHsiaHJlZiI6Imh0dHBzOi8vcGxhdGZvcm0tcnVsZS1lZGl0b3IuYXBwcy5ob3Jpem9uZGV2LmNsb
  3VkLyMvc3NvIiwiaWNvbiI6Im1hcmVuZ28tcHJvY2Vzcy12YWx1ZSBlbGVtZW50LXByb2Nlc3MtdmFsdWUiLCJpZCI6
  ImFwcC5ydWxlRWRpdG9yIiwidGl0bGUiOiJSdWxlIEVkaXRvciJ9LHsiaHJlZiI6Imh0dHBzOi8vYXAtc2hlbGwtc2V
  ydmVyLmJ1aWxkaW5nMzYwLXRlc3Quc2llbWVucy5jbG91ZCIsImljb24iOiJtYXJlbmdvLXJvb20tdGVtcGVyYXR1cm
  UgZWxlbWVudC1lbmVyZ3ktbWFuYWdlciIsImlkIjoiYXBwLmVuZXJneUFuZFN1c3QiLCJ0aXRsZSI6IkVuZXJneSBNY
  W5hZ2VyIn0seyJocmVmIjoiaHR0cHM6Ly9zdGFnZS5kaWdpdGFsdHdpbi5idC5zaWVtZW5zLmNsb3VkIiwiaWNvbiI6
  Im1hcmVuZ28tcm9vbS0zZCBlbGVtZW50LXJvb20tM2QiLCJpZCI6ImFwcC5idWlsZGluZ1R3aW4iLCJ0aXRsZSI6IjM
  2MMKwIFZpZXdlciJ9LHsiaHJlZiI6Imh0dHBzOi8vZGF0YS1jbGFzc2lmaWVyLmFwcHMuaG9yaXpvbmRldi5jbG91ZC
  8jL3NzbyIsImljb24iOiJtYXJlbmdvLXRhZyBlbGVtZW50LXRhZyIsImlkIjoiYXBwLmRhdGFDbGFzc2lmaWVyIiwid
  Gl0bGUiOiJEYXRhIFNldHVwIn1d`,
  googleAPIChannel: '107',
  aMapApiKey: '146f101e824accd6956eeec4937c1a05'
};
