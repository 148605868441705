import { Injectable } from '@angular/core';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { SolaceMessageClient } from '@solace-community/angular-solace-message-client';
import { AuthenticationScheme, Session } from 'solclientjs';

@Injectable({
  providedIn: 'root'
})
export class SolaceService {
  isConnected = false;
  //private timerId: NodeJS.Timer | undefined;

  constructor(private solaceMessageClient: SolaceMessageClient,
    private readonly siemensIdService: AuthenticationService) {
  }

  connectOAuth2(url: string, vpnName: string) {
    const token = this.getToken();
    this.solaceMessageClient.connect(
      {
        url,
        vpnName,
        authenticationScheme: AuthenticationScheme.OAUTH2,
        accessToken: token
      }).then((session: Session) => {
      console.log('connected to Solace (OAuth2)');
      this.isConnected = true;
    }).catch(error => {
      console.log(error);
    });
  }

  disconnect() {
    this.solaceMessageClient.disconnect()
      .then(() => {
        console.log('disconnected from Solace');
        this.isConnected = false;
      }).catch(error => {
        console.log(error);
      });
  }

  getSolaceButtonText(): string {
    if (this.isConnected) {
      return 'Disconnect';
    } else {
      return 'Connect';
    }
  }

  getToken(): string {
    return this.siemensIdService.accessToken;
  }

  // private simulate() {
  //   this.timerId = setInterval(() => {
  //     const pvValue = Math.random() * 600;
  //     const batteryValue = Math.random() * 100;
  //     const date = Date.now();
  //     this.publish('device/12345', 'pv', date, pvValue);
  //     this.publish('device/12345', 'battery', date, batteryValue);
  //   }, 1000);
  // }

  // publish(topic: string, id: string, timestamp: number, value: number) {
  //   const json = '{"data":{"id":"' + id + '","timestamp":' + timestamp + ',"value":' + value + '}}';
  //   this.solaceMessageClient.publish('device/12345', json);
  // }

}
