import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Component } from '@angular/core';
import { SiLoginInterceptor } from '@building-x/common-ui-ng';
import { StatusType } from '@simpl/element-ng';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiLoginInterceptor,
      multi: true
    }
  ]
})
export class LoginComponent {
  warningText = `You are about to connect to productive data. Please note that changes will take effect immediately.`;
  backgroundImageUrl = 'assets/images/login-image/AccountManager.jpg';
  messageSeverity: StatusType = 'warning';
  inlineNotification = {
    message: 'Maintenance is planned at 10:00 - 12:00 GMT', severity: this.messageSeverity
  };
  languages = [{ value: 'en', name: 'English' }, { value: 'de', name: 'Deutsch' }];
}
