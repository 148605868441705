import { Injectable } from '@angular/core';
import { SiDefaultWidgetStorage, SiGridComponent, SiWidgetStorage, WidgetConfig } from '@simpl/dashboards-ng';
import { MenuItem } from '@simpl/element-ng';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { Config } from './shared/models/config.model';
import { ConfigService } from './shared/services/config.service';
import { WIDGETS_1, WIDGETS_2, WIDGETS_3 } from './widgets/widgets-default-config';


const CUSTOM_DEFAULTS = 'app-default-widgets';

@Injectable({
  providedIn: 'root'
})
export class AppWidgetStorage extends SiDefaultWidgetStorage implements SiWidgetStorage {

  toolbarPrimaryMenuItems$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  private configMap = new Map<string, BehaviorSubject<WidgetConfig[]>>();
  private simWidgetDashboardList = ['b84e6f97-cb15-4dde-a712-3479b634be06', '0cd76e00-0e42-43f5-89df-f9cb321d75d1'];

  constructor(private configService: ConfigService) {
    super();
    this.storage = window.localStorage;

    const items = [
      { title: 'TOOLBAR.RESTORE_DEFAULTS', action: (grid: SiGridComponent) => this.restoreDefaults(grid) },
      { title: 'TOOLBAR.SAVE_AS_DEFAULTS', action: (grid: SiGridComponent) => this.saveAsDefaults(grid) }
    ];
    this.toolbarPrimaryMenuItems$.next(items);
  }

  restoreDefaults = (grid: SiGridComponent) => this.doRestoreDefaults();

  saveAsDefaults = (grid: SiGridComponent) => {
    grid.transientWidgetInstances.forEach(widget => widget.id = this.getRandomId());
    this.storage.setItem(CUSTOM_DEFAULTS, JSON.stringify(grid.visibleWidgetInstances$.value));
    return of<void>();
  };

  private doRestoreDefaults() {
    const myDefaultsStr = this.storage.getItem(CUSTOM_DEFAULTS);
    let widgets: WidgetConfig[] | undefined;
    if (myDefaultsStr !== null) {
      widgets = JSON.parse(myDefaultsStr);
    }

    this.update(widgets ?? WIDGETS_1);
    return of();
  }

  load(dashboardId?: string): Observable<WidgetConfig[]> {
    if (!dashboardId) {
      return new BehaviorSubject<WidgetConfig[]>(this.loadFromStorage());
    } else {
      this.configMap.set(dashboardId, new BehaviorSubject<WidgetConfig[]>(this.loadFromStorage(dashboardId)));
    }
    return this.configMap.get(dashboardId)!;
  }

  protected loadFromStorage(dashboardId?: string): WidgetConfig[] {
    let widgetConfigs = super.loadFromStorage(dashboardId);
    if (widgetConfigs.length === 0 && dashboardId) {
      widgetConfigs = this.simWidgetDashboardList.includes(dashboardId) ? WIDGETS_3.map(x => ({ ...x })) : WIDGETS_2.map(x => ({ ...x }));
      for (const widget of widgetConfigs) {
        widget.id = this.getRandomId();
        this.configService.getConfig().subscribe({
          next: (config: Config) => {
            widget.payload.site = config.site;
            widget.payload.latitude = config.latitude;
            widget.payload.longitude = config.longitude;
            widget.payload.partitionId = config.partitionId;
            widget.payload.gatewayId = config.gatewayId;
            widget.payload.siteId = config.siteId;
          }
        });
      }
    }
    return widgetConfigs;
  }

  private getRandomId() {
    const arr = new Uint32Array(1);
    const id = window.crypto.getRandomValues(arr)[0];
    return id.toString(36).substring(2, 9);
  }
}
