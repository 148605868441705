import { Widget } from '@simpl/dashboards-ng';

const loaderFunction = (name: string) => {
  if (name === 'WeatherWidgetComponent'
    || name === 'WeatherWidgetEditorComponent'
    || name === 'WeatherForecastWidgetComponent'
    || name === 'EfficiencyWidgetComponent'
    || name === 'PvBatteryWidgetComponent'
    || name === 'IframeWidgetComponent'
    || name === 'DrWidgetComponent'
    || name === 'DrWidgetEditorComponent'
    || name === 'SiteStatusWidgetComponent'
    || name === 'SimulationCockpitWidgetComponent') {
    return import('src/app/widgets/ews/index');
  } else {
    throw new Error(`Unknown component to be loaded ${name}`);
  }
};

export const EWS_WEATHER_WIDGET_DESCRIPTOR: Widget = {
  name: 'EWS Weather',
  id: '@simpl/dashboards-ng/ews/weather',
  iconClass: 'element-report',
  description: 'A weather widget.',
  componentFactory: {
    componentName: 'WeatherWidgetComponent',
    editorComponentName: 'WeatherWidgetEditorComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  defaults: {
    width: 1,
    height: 3,
    expandable: true
  }
};

export const EWS_WEATHER_FORECAST_WIDGET_DESCRIPTOR: Widget = {
  name: 'Weather Forecast',
  id: '@simpl/dashboards-ng/line-chart',
  description: `A weather forcast.`,
  iconClass: 'element-trend',
  componentFactory: {
    componentName: 'WeatherForecastWidgetComponent',
    editorComponentName: 'WeatherWidgetEditorComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  defaults: {
    width: 6,
    height: 3,
    heading: 'Weather Forecast',
    expandable: true
  },
  payload: {
    datasourceId: 'getLineChartData',
    config: {
      stacked: false,
      showLegend: true
    }
  }
};

export const EWS_PV_BATTERY_WIDGET_DESCRIPTOR: Widget = {
  name: 'PV and Battery State',
  id: '@simpl/dashboards-ng/ews/pv-battery',
  description: `A widget for visualizing PV and battery data.`,
  iconClass: 'element-trend',
  componentFactory: {
    componentName: 'PvBatteryWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  defaults: {
    width: 6,
    height: 3,
    heading: 'PV and Battery real-time data',
    expandable: true
  },
  payload: {
    datasourceId: 'getLineChartData',
    config: {
      stacked: false,
      showLegend: true
    }
  }
};

export const EWS_EFFICIENCY_WIDGET_DESCRIPTOR: Widget = {
  name: 'EWS Efficiency',
  id: '@simpl/dashboards-ng/ews/efficiency',
  iconClass: 'element-report',
  description: 'An efficiency widget.',
  componentFactory: {
    componentName: 'EfficiencyWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  defaults: {
    width: 4,
    height: 3,
    expandable: true
  }
};

export const EWS_IFRAME_WIDGET_DESCRIPTOR: Widget = {
  name: 'EWS Wrapper',
  id: '@simpl/dashboards-ng/ews/wrapper',
  iconClass: 'element-report',
  description: 'A widget that includes an iframe.',
  componentFactory: {
    componentName: 'IframeWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  defaults: {
    width: 4,
    height: 3,
    expandable: true
  }
};

export const EWS_LEMS_COLLECT_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS Collect',
  id: '@simpl/dashboards-ng/ews/lems-collect',
  iconClass: 'element-report',
  description: 'A widget for displaying DR asset points',
  componentFactory: {
    componentName: 'DrWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 12,
    height: 5,
    expandable: true
  }
};

export const EWS_LEMS_CONTROL_DR_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS Control - Demand Response',
  id: '@simpl/dashboards-ng/ews/lems-control-dr',
  iconClass: 'element-report',
  description: 'A widget for displaying DR asset points',
  componentFactory: {
    componentName: 'DrWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 12,
    height: 5,
    expandable: true
  }
};

export const EWS_LEMS_CONTROL_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS Control',
  id: '@simpl/dashboards-ng/ews/lems-control',
  iconClass: 'element-report',
  description: 'A widget for displaying DR asset points',
  componentFactory: {
    componentName: 'DrWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 12,
    height: 5,
    expandable: true
  }
};

export const EWS_SIMULATION_DEMO_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS Control - Live Simulation Demo',
  id: '@simpl/dashboards-ng/ews/simulation-demo',
  iconClass: 'element-report',
  description: 'A widget for displaying Simulation trend',
  componentFactory: {
    componentName: 'DrWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 12,
    height: 5,
    expandable: true
  }
};

export const EWS_SITE_STATUS_WIDGET_DESCRIPTOR: Widget = {
  name: 'Site Status',
  id: '@simpl/dashboards-ng/ews/site-status',
  iconClass: 'element-report',
  description: 'A widget for displaying site status',
  componentFactory: {
    componentName: 'SiteStatusWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 2,
    height: 3,
    expandable: true
  }
};

export const EWS_GENERAL_PURPOSE_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS General Purpose',
  id: '@simpl/dashboards-ng/ews/lems-general-purpose',
  iconClass: 'element-report',
  description: 'A generic widget for displaying configurable asset points',
  componentFactory: {
    componentName: 'DrWidgetComponent',
    editorComponentName: 'DrWidgetEditorComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 8,
    height: 5,
    expandable: true
  }
};

export const EWS_SIMULATION_COCKPIT_WIDGET_DESCRIPTOR: Widget = {
  name: 'LEMS Control - Simulation Cockpit',
  id: '@simpl/dashboards-ng/ews/simulation-cockpit',
  iconClass: 'element-report',
  description: 'A widget for displaying Simulation configuration cockpit',
  componentFactory: {
    componentName: 'SimulationCockpitWidgetComponent',
    moduleName: 'WidgetsModule',
    moduleLoader: loaderFunction
  },
  payload: {},
  defaults: {
    width: 4,
    height: 5,
    expandable: false
  }
};