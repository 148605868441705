import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@building-x/common-ui-ng';

@Component({
  selector: 'app-sso',
  template: ''
})
export class SingleSignOnComponent implements OnInit {
  constructor(
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authService.signIn();
  }
}
