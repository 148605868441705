import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SiLoginInterceptor, SiSharedModule, SiUOModule } from '@building-x/common-ui-ng';
import { TranslateModule } from '@ngx-translate/core';
import { SimplElementNgModule } from '@simpl/element-ng';
import { ModalModule } from 'ngx-bootstrap/modal';

import { environment } from '../../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    SiUOModule.forRoot({ apiUrl: environment.userApiUrlV2 }),
    SimplElementNgModule,
    SiSharedModule,
    TranslateModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SiLoginInterceptor,
      multi: true
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class UsersModule { }
