<div class="container-fluid si-layout-fixed-height si-dashboard si-layout-content-padding sticky">
  <h2 class="page-top-title text-secondary mb-7 ng-star-inserted">Onboard a Device</h2>
  <form [formGroup]="form">
    <si-form-container
      #formContainer
      [form]="form"
      [enableValidationHelp]="true"
    >
      <div si-form-container-content>
        <div class="mb-6">
          <label for="activationKey" class="form-label">Device Activation Key</label>
          <input type="text" class="form-control" formControlName="activationKey" id="activationKey">
        </div>
        <div class="mb-6">
          <label for="deviceName" class="form-label">Device Name</label>
          <input type="text" id="deviceName" class="form-control" formControlName="deviceName">
        </div>
        <div class="mb-6">
          <label for="siteInput" class="form-label">Site</label>
          <select id="siteInput" aria-label="number" class="form-select" formControlName="site">
            <option value="" selected disabled>Select Site</option>
            <option *ngFor="let building of buildings; trackBy: trackByIndex" [value]="building.id">
              {{ building.name }}
            </option>
          </select>
        </div>
        <button type="button" [disabled]='onboardingDisabled || form.invalid' (click)="onboardDevice()" class="btn btn-primary me-6"><i class="icon element-device"></i>Onboard Device</button>
      </div>
    </si-form-container>
  </form>
  <hr>
  <br>
  <h2 class="page-top-title text-secondary mb-7 ng-star-inserted">Delete a Device</h2>
  <form [formGroup]="deleteForm">
    <si-form-container
      #formContainer
      [form]="deleteForm"
      [enableValidationHelp]="true">
      <div si-form-container-content>
        <div class="mb-6">
          <label for="siteInput" class="form-label">Site</label>
          <select id="siteInput" class="form-select" formControlName="site" (change)="getDeviceDetails()">
            <option value="" selected disabled>Select Site</option>
            <option *ngFor="let site of buildings; trackBy: trackByIndex" [value]="site.id">{{site.name}}</option>
          </select>
        </div>
        <div class="mb-6">
          <label for="deviceName" class="form-label">Device Name</label>
          <select id="deviceName" class="form-select" formControlName="deviceName" (change)="getActivationKey()">
            <option value="" selected disabled>Select Device</option>
            <option *ngFor="let device of deviceNames; trackBy: trackByIndex">{{device}}</option>
          </select>
        </div>
        <div class="mb-6">
          <label for="activationKey" class="form-label">Device Activation Key</label>
          <input type="text" id="activationKey" readonly class="form-control" formControlName="activationKey">
        </div>
        <button type="button" [disabled]='deleteForm.invalid' (click)="deleteConfirmation()" class="btn btn-primary me-6"><i class="icon element-device"></i>Delete Device</button>
      </div>
    </si-form-container>
  </form>
</div>
<si-toast-notification-drawer></si-toast-notification-drawer>
