import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

import { DeviceOnboardingRequest } from '../models/deviceOnboarding.model';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor(private http: HttpClient) {}

  public onboardDevice(deviceName: string, gatewayId: string, partitionId: string, locationId: string, accessToken: string): Observable<any> {
    const uri = '/api/device-configuration/devices';

    const body: DeviceOnboardingRequest = { deviceName, gatewayId, partitionId, locationId, accessToken };

    let headers = new HttpHeaders();
    headers = headers.append('X-Partition', partitionId);

    return this.http.post(uri, body, { headers, observe: 'response' }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  public getDeviceDetails(partitionId: string, locationId: string): Observable<any> {
    const uri = 'https://product-int-dm.device.horizonint.cloud/api/v2/partitions/' + partitionId +
    '/devices?page[limit]=100&filter[hasLocation.id]=' + locationId;

    return this.http.get(uri).pipe(
      map((response: any) => response?.data),
      catchError(this.handleError)
    );
  }

  public deleteDevice(gatewayId: string, accessToken: string) {
    const uri = '/api/device-configuration/devices/' + gatewayId;

    let headers = new HttpHeaders();
    headers = headers.append('dcAccessToken', accessToken);

    return this.http.delete(uri, { headers }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: any) {
    const errorMsg = 'Device Service: ERROR';
    console.log(errorMsg);
    return throwError(() => error?.error);
  }
}
