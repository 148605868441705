import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuildingsService {

  private buildingsSubject: BehaviorSubject<Building[]> = new BehaviorSubject([] as Building[]);

  constructor(private http: HttpClient) {
  }


  getBuildingsSub(): BehaviorSubject<Building[]> {
    return this.buildingsSubject;
  }

  getBuildings(customerEvent: any): BehaviorSubject<Building[]> {
    const buildings: Building[] = [];
    const observables: Observable<any>[] = [];
    const baseUri = 'https://product-int.locations.horizonint.cloud/v2/partitions/';
    for (const partition of customerEvent.selectedPartitions) {
      const uri = baseUri + partition.id + '/locations?filter[type]=Building';

      observables.push(this.http.get(uri).pipe(map((response: any) => response)));
    }

    forkJoin(observables).subscribe({
      next:
        data => {
          for (const item of data) {
            if (item.data.length !== 0) {
              const partition = item.links.self.substring(baseUri.length, baseUri.length + 36);
              for (const building of item.data) {
                const buildingName = building.attributes.label;
                const id = building.id;
                const latitude: number = building.attributes?.geoLocation?.geometry?.coordinates[1];
                const longitude: number = building.attributes?.geoLocation?.geometry?.coordinates[0];
                buildings.push(new Building(buildingName, id, partition, latitude, longitude));
              }
            }
          }
          this.buildingsSubject.next(buildings);
        },
      error: err => this.handleError(err)
    });
    return this.buildingsSubject;
  }

  private handleError(error: any) {
    const errMsg = 'Building Service: cannot get buildings from http server.';
    console.error(errMsg); // log to console instead
    return throwError(() => new Error(errMsg));
  }

}

export class Building {
  constructor(public name: string, public id, public partition, public latitude: number, public longitude: number) {
  }
}
