/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import {
  DataZoomEvent,
  findIndexes,
  MissingChartData,
  RangeType,
  TrendData,
  TrendLiveRequest,
  TrendLiveSeries,
  TrendviewerApi,
  TrendViewerViewState
} from '@simpl/trendviewer-ng';
import { SolaceMessageClient } from '@solace-community/angular-solace-message-client';
import { Observable, of, Subscription } from 'rxjs';

const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;

const TRENDSTATE: TrendViewerViewState = {
  activeView: 'view'
};

class DummySeries {
  public fullData: any[][] = [];
  private lowresData: any[][] = [];
  private zoomThresholdDays: number;
  private fullDataMissingSample: MissingChartData = [];
  private lowresMissingSample: MissingChartData = [];

  public interval: any;

  constructor(name: string, startDate: Date, startValue: number, zoomThresholdDays: number,
    numPoints: number, quality = true, skipSomeLive = false
  ) {

    this.zoomThresholdDays = zoomThresholdDays;

    this.fullData = [[+startDate, startValue]];
    this.lowresData = [];
    this.fullDataMissingSample = [];
  }

  public getZoomLevel(dataZoomEvent: DataZoomEvent): number {
    const delta = dataZoomEvent.rangeEnd - dataZoomEvent.rangeStart;
    if (delta < oneDay * this.zoomThresholdDays) {
      // <90 days: hi-res
      return 1;
    }

    return 0;
  }

  public getNumDatapoints() {
    return this.fullData.length;
  }

  public getSeriesData(zoomLevel: number, range: RangeType | null): TrendData {
    if (zoomLevel === 0 || range === null) {
      return {
        chartData: this.lowresData,
        missingData: this.lowresMissingSample
      };
    }

    // zoomLevel 1: hi-res data
    const indexes = findIndexes(this.fullData, range);
    const chartDataSliced = this.fullData.slice(indexes.startIndex, indexes.endIndex + 100);
    const missingdata = this.fullDataMissingSample.slice(indexes.startIndex, indexes.endIndex + 1);
    return { chartData: chartDataSliced, missingData: missingdata };
  }
}

// demo: generate random data, one zoom level
@Injectable()
export class DemoTrendviewerApiService implements TrendviewerApi {
  public series: { [key: string]: DummySeries } = {};

  private subscriptions: Subscription[] = [];
  private observers = {};

  constructor(private solaceMessageClient: SolaceMessageClient) {
  }

  private getOrCreateSeries(name: string): DummySeries {
    let series = this.series[name];
    if (!series) {
      this.series[name] = series = new DummySeries(name, new Date(2023, 1, 1, 0, 0, 0), 50, 60,
        2000, false);
    }
    return series;
  }

  getSeriesData(instanceId: string, series: string, zoomLevel: number, range: RangeType | null) {
    return new Observable<TrendData>(observer => {
      const data = this.getOrCreateSeries(series).getSeriesData(zoomLevel, range);

      observer.next(data);
    });
  }

  getZoomLevel(instanceId: string, series: string, dataZoomEvent: DataZoomEvent): number {
    return this.getOrCreateSeries(series).getZoomLevel(dataZoomEvent);
  }

  startLiveDataUpdate(instanceId: string, series: TrendLiveRequest) {
    console.log('start live update', series.identifier);
    if (this.subscriptions.length === 0) {
      this.subscriptions.push(
        this.solaceMessageClient.observe$('gatewayId/data').subscribe(envelope => {
          const jsonString: string = envelope.message.getBinaryAttachment() as string;
          const object = JSON.parse(jsonString);
          const id = object.data.id;

          let seriesName = '';
          if (id === '/.things/urn:battery_eumulated_ERLLAB/SOC') {
            seriesName = 'battery-soc';
          } else if (id === '/.things/urn:load_emulation_ERLLAB/TotalActivePower') {
            seriesName = 'load-tap';
          } else if (id === '/.things/urn:battery_eumulated_ERLLAB/TotalActivePower') {
            seriesName = 'battery-tap';
          } else if (id === '/.things/urn:PAC_ERLLAB/TotalActivePower') {
            seriesName = 'pcc-tap';
          }
          const observer = this.observers[seriesName];
          if (observer) {
            const timestamp = object.data.timestamp;
            const value = object.data.value;
            const trendLiveSeries = {
              identifier: seriesName,
              zoomLevel: 0,
              chartData: [[timestamp, value]]
            };
            observer.next(trendLiveSeries);
            console.log('solace update for ' + id + ': ', value);
          }
        }));
    }

    return new Observable<TrendLiveSeries>(observer => {
      console.log('start live update', instanceId, series.identifier);
      const seriesObj = this.series[series.identifier];
      if (seriesObj) {
        console.log('store observer', series.identifier);
        this.observers[series.identifier] = observer;
      }
    });
  }

  stopLiveDataUpdate(instanceId: string, series: TrendLiveRequest): void {
    const seriesObj = this.series[series.identifier];
    if (seriesObj) {
      clearInterval(seriesObj.interval);
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getViewState(instanceId: string) {
    return of(TRENDSTATE);
  }
}
