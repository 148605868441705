import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LemsAdapterService {

  constructor(private http: HttpClient) {
  }

  public getEfficiency(partitionId: string): Observable<any[]> {
    const uri = '/api/lems/efficiency';

    let headers = new HttpHeaders();
    headers = headers.append('X-Partition', partitionId);

    return this.http.get(uri, { headers }).pipe(
      map((response: any) => response),
      catchError(this.handleError));
  }

  private handleError(error: any) {
    const errMsg = 'LEMS adapter Service: cannot get data from http server.';
    console.error(errMsg);
    return throwError(() => new Error(errMsg));
  }

}
