import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CustomerEventService {
  private customerSubject: BehaviorSubject<any> = new BehaviorSubject('');

  public getCustomerEventSubject(): BehaviorSubject<any> {
    return this.customerSubject;
  }
  public setCustomerEvent(customerEvent: any): void {
    this.customerSubject.next(customerEvent);
  }
}

