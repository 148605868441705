import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SiChartComponent } from '@simpl/charts-ng';
import { WidgetConfig, WidgetInstance } from '@simpl/dashboards-ng';

import { LemsAdapterService } from '../../../shared/services/lems-adapter.service';

@Component({
  selector: 'app-efficiency-widget',
  templateUrl: './efficiency-widget.component.html'
})
export class EfficiencyWidgetComponent implements OnInit, AfterViewInit, WidgetInstance {
  options: any;
  xaxis: any;
  yaxis: any;
  series: any;
  initialized = false;
  errorText?: string;
  errorDesc = 'UNAUTHORIZED_PAGE.DESCRIPTION';
  error = false;

  @ViewChild('chart') chart: SiChartComponent | undefined;
  @Input() config!: WidgetConfig;

  constructor(private lemsAdapterService: LemsAdapterService) {
    this.xaxis = {
      type: 'category',
      data: []
    };

    this.yaxis = {
      type: 'value',
      axisLabel: {
        formatter: '{value} %'
      }
    };

    this.series = [{
      type: 'bar',
      name: 'DG',
      itemStyle: {
        color: {
          type: 'linear',
          x: 1,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: '#1869ff'
          }, {
            offset: 1,
            color: '#7baaff'
          }
          ]
        }
      },
      data: []
    }, {
      type: 'bar',
      name: 'PV',
      itemStyle: {
        color: {
          type: 'linear',
          x: 1,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: '#fd9f23'
          }, {
            offset: 1,
            color: '#fcb266'
          }
          ]
        }
      },
      data: []
    }, {
      type: 'bar',
      name: 'BESS',
      itemStyle: {
        color: {
          type: 'linear',
          x: 1,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: '#fc5419'
          }, {
            offset: 1,
            color: '#ff795b'
          }
          ]
        }
      },
      data: []
    }];

    this.options = {
      toolbox: {
        feature: {
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      title: {
        text: 'Efficiency (Sunny Island Demo)'
      },
      legend: [
        {
          left: '10',
          data: ['DG', 'PV', 'BESS']
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: params => {
          let message = '';
          message += `${params[0].axisValueLabel}`;
          params.forEach(param => {
            if (param.value) {
              param.unit = '%';
            }
            message += `<br/>${param.marker}${param.seriesName}: ${Math.round(param.value * 100) / 100} ${param.unit || ''}`;
          });
          return message;
        }
      },
      xAxis: this.xaxis,
      yAxis: this.yaxis,
      series: this.series
    };
  }

  ngAfterViewInit() {
    if (this.chart?.chart) {
      this.chart.chart.showLoading({ maskColor: 'rgb(0,0,0,0', text: '' });
      this.chart.chart.on('restore', () => { this.chart?.chart.setOption(this.options); });
    }
  }

  ngOnInit(): void {
    this.initialized = false;
    this.lemsAdapterService.getEfficiency(this.config?.payload?.partitionId)
      .subscribe({
        next: (response: any) => {
          this.xaxis.data = response.data.attributes.date;
          this.series[0].data = response.data.attributes.dg;
          this.series[1].data = response.data.attributes.pv;
          this.series[2].data = response.data.attributes.bess;
          this.error = false;
          this.initialized = true;
          if (this.chart) {
            this.chart.chart.hideLoading();
            this.chart.chart.setOption(this.options);
          }
        },
        error: error => {
          this.errorText = 'UNAUTHORIZED_PAGE_EFFICIENCY_WIDGET_NO_DATA';
          this.error = true;
          this.initialized = true;
        }
      });

  }

}
