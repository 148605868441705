import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppSidePanelStateService,
  AuthenticationService,
  Customer,
  Language,
  NavbarItemTypes,
  PointOfContact,
  SiCookiesService,
  SiProfileService,
  UpdateNavbarType
} from '@building-x/common-ui-ng';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, SiSidePanelService, SiToastNotificationService } from '@simpl/element-ng';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ProfileInfo } from '../shared/models/types';
import { BuildingsService } from '../shared/services/buildings.service';
import { CustomerEventService } from '../shared/services/customer-event.service';
import { Localization } from '../shared/services/localization.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {

  languages: Language[] = [{ value: 'en', name: 'English' }, { value: 'de', name: 'Deutsch' }];

  persistCustomerId: string | undefined;
  showSecondaryNavbar = false;
  public accountInfo: ProfileInfo = { title: '' };
  public accountItem = [];
  secondaryNavbarLinks: MenuItem[] = [];
  pointOfContactDetails: PointOfContact = {
    name: 'Kai Tödter',
    email: 'kai.toedter@siemens.com',
    // phone: '+91-9970209322',
    hasError: false
  };
  additionalMenuItem: MenuItem[] = [];
  additionalInfoLinks = [];
  resetNavbar: Subject<boolean> = new Subject<boolean>();
  updateOrganization: Subject<UpdateNavbarType> = new Subject<UpdateNavbarType>();
  appSwitcherDataSource = environment.webappVerticalApiUrl;

  showBreadCrumb = true;
  breadCrumbItems = [{ title: '', link: '' }];
  collapsed;
  collapseCustomerSwitcher = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly profileService: SiProfileService,
    private readonly cookieService: SiCookiesService,
    private readonly localization: Localization,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly toastNotificationService: SiToastNotificationService,
    private readonly customerEventService: CustomerEventService,
    private readonly sidePanelService: SiSidePanelService,
    private readonly appSidePanelStateService: AppSidePanelStateService,
    private readonly buildingService: BuildingsService
  ) {
  }

  ngOnInit(): void {
    this.persistCustomerId = this.cookieService.getCookie('customerId');

    this.localization.detectLanguage();

    this.profileService.getProfileData().subscribe(user => {
      this.accountInfo = {
        title: user.attributes.identity.fullName,
        image: user.attributes.profile.profileImage
      };
    }, () => {
      this.toastNotificationService.queueToastNotification(
        'connection',
        this.translateService.instant('ERROR.CONNECTION_ERROR_TITLE'),
        this.translateService.instant('ERROR.CONNECTION_ERROR_TEXT')
      );
    });

    const dashboardItems: MenuItem[] = [];
    this.secondaryNavbarLinks = [
      {
        title: 'Dashboards',
        icon: 'element-trend',
        items: dashboardItems
      },
      {
        title: 'Self Service',
        icon: 'element-maintenance',
        items: [
          {
            title: 'Device Onboarding',
            icon: 'element-device',
            link: '/main/onboarding/?'
          }
        ]
      },
      {
        title: 'Experimental',
        icon: 'element-alchemy-flask',
        items: [
          {
            title: 'DR Dashboard',
            icon: 'element-special-object',
            link: '/main/dr-dashboard',
            navigationExtras: { queryParams: { type: null } }
          },
          {
            title: 'DR Demo',
            icon: 'element-special-object',
            link: '/main/dr-demo',
            navigationExtras: { queryParams: { type: null } }
          }
        ]
      }
    ];

    this.customerEventService.getCustomerEventSubject().subscribe(customerEvent => {
      if (customerEvent === '') {
        return;
      }

      this.buildingService.getBuildings(customerEvent)
        .subscribe({
          next: (response: any) => {
            const buildings = response;
            dashboardItems.length = 0;
            if (buildings && buildings.length > 0) {
              for (const building of buildings) {
                const item = {
                  title: building.name,
                  icon: 'element-special-object',
                  link: '/main/dashboard/' + building.id,
                  navigationExtras: { queryParams: { type: null } }
                };
                dashboardItems.push(item);
              }
            }
          },
          error: error => {
            console.error('MainComponent: cannot get data from BuildingService');
          }
        });
    });

    this.showSecondaryNavbar = false;
    setTimeout(() => {
      this.updateUrlParams();
    }, 10000);
    this.subscribeToCollapsePanel();
  }

  public logout(): void {
    this.authenticationService.signOut();
  }

  onOrgChange(ev: any) {
    console.log('org changed: ' + ev);
  }

  onLogout(ev: any) {
    this.router.navigate(['/logout']);
  }

  // to refresh navbar
  refreshNavbar() {
    this.resetNavbar.next(true);
  }

  updateUrlParams() {
    const paramObj: UpdateNavbarType = {
      navbarItemType: NavbarItemTypes.UpdateParams,
      objectValue: [{
        keyId: 'site',
        value: '123'
      }]
    };
    this.updateOrganization.next(paramObj);
  }

  customerSelection(event: any) {
    this.setCookie(event.selectedCustomer);

    this.breadCrumbItems = [];
    this.breadCrumbItems.push(
      { title: '', link: '' },
      { title: event.selectedCustomer.attributes.name || '', link: '' }
    );
    this.showBreadCrumb = true;

    this.customerEventService.setCustomerEvent(event);
  }

  setCookie(customer: Customer) {
    const cookieExpiry = new Date();
    cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    this.cookieService.setCookie(
      'customerId',
      customer.id,
      '',
      cookieExpiry.toUTCString()
    );
    this.cookieService.setCookie(
      'customerName',
      customer.attributes.name,
      '',
      cookieExpiry.toUTCString()
    );
  }

  subscribeToCollapsePanel() {
    this.appSidePanelStateService.isSidePaneCollapsed.subscribe(result => {
      this.collapsed = result;
    });
    return this.collapsed;
  }

  closeCustomerSwitcher() {
    this.sidePanelService.setSidePanelContent(undefined);
    this.collapseCustomerSwitcher = false;
    this.appSidePanelStateService.isSidePaneCollapsed.next(true);
  }
}
