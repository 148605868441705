import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AddSeriesType,
  ChartConfiguration,
  clone,
  SiSymbol,
  SiTrendviewerService,
  TrendSeries
} from '@simpl/trendviewer-ng';
import { delay, Subscription } from 'rxjs';

import { DemoTrendconfigApiService } from './api/demo-trendconfig-api.service';

@Component({
  selector: 'app-dr-dashboard',
  templateUrl: './dr-dashboard.component.html',
  styleUrls: ['./dr-dashboard.component.scss']
})
export class DrDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  instanceId = 'DR-Sim';
  rowDetails: any;

  constructor(
    private service: SiTrendviewerService,
    private configService: DemoTrendconfigApiService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.configService.chartSeries.subscribe(series => this.seriesPropertyChanged(series)));
    this.subscriptions.push(this.configService.addDataPointRequest.pipe(delay(100)).subscribe(gridIndex =>
      this.addBatterySeries()));
    this.subscriptions.push(this.configService.locationSelection.subscribe(() => {
      this.service.setChartProperties(this.instanceId, { location: { id: 'folder2', path: 'Folder2', enable: true } });
    }));
  }

  ngAfterViewInit() {
    setTimeout(() => this.addData());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  seriesPropertyChanged(series: TrendSeries): void {
    // Basic idea behind series property change api is that the client should use same properties
    // while adding the series, but it has now the option to change the id with which it fetches the data
    // On property change, it is required to send existing series identifier to replace the series instead of
    // removing old series and adding new one in coreservice.series Map.
    this.service.addSeries(this.instanceId, {
      gridIndex: series.gridIndex,
      identifier: series.identifier,
      properties: series.properties
    }, AddSeriesType.PropertyChange);
  }

  addData() {
    const props: ChartConfiguration = {
      title: 'Peak Shaving Emulation',
      subTitle: 'Erlangen Test Lab',
      yAxisPointer: true,
      dataZoomRange: {
        startValue: new Date().valueOf(),
        endValue: new Date().valueOf()
      },
      relativeTimeConfiguration: { relativeTimeRange: 'default', unitValue: 0 },
      showCustomLegend: true
    };

    this.service.setChartProperties(this.instanceId, props);

    this.addPowerSeries1();
    this.addPowerSeries2();
    this.addPowerSeries3();
    this.addBatterySeries();
  }

  addPowerSeries1() {
    this.service.addSeries(this.instanceId, {
      identifier: 'load-tap',
      gridIndex: 0,
      properties: {
        name: 'load-active-power',
        displayName: 'Load (Active Power)',
        smooth: true,
        yAxisProperties: {
          position: 'left',
          type: 'value',
          scale: false,
          name: 'KW',
          min: -30,
          max: 30
        },
        missingDataOpacity: 0.7,
        lineWidth: 3,
        lineType: 'solid',
        lineColor: '#257c06',
        legendTooltipText: 'active power tooltip',
        unit: 'KW',
        resolution: 2
      }
    }, AddSeriesType.Initial);
  }
  addPowerSeries2() {
    this.service.addSeries(this.instanceId, {
      identifier: 'battery-tap',
      gridIndex: 0,
      properties: {
        name: 'battery-active-power',
        displayName: 'Battery (Active Power)',
        smooth: true,
        yAxisProperties: {
          position: 'left',
          type: 'value',
          scale: false,
          name: 'KW',
          min: -30,
          max: 30
        },
        missingDataOpacity: 0.7,
        lineWidth: 3,
        lineType: 'solid',
        lineColor: '#d05f18',
        legendTooltipText: 'battery power tooltip',
        unit: 'KW',
        resolution: 2
      }
    }, AddSeriesType.Initial);
  }
  addPowerSeries3() {
    this.service.addSeries(this.instanceId, {
      identifier: 'pcc-tap',
      gridIndex: 0,
      properties: {
        name: 'pcc-active-power',
        displayName: 'PCC (Active Power)',
        smooth: true,
        yAxisProperties: {
          position: 'left',
          type: 'value',
          scale: false,
          name: 'KW',
          min: -30,
          max: 30
        },
        missingDataOpacity: 0.7,
        lineWidth: 3,
        lineType: 'solid',
        lineColor: '#f39e1c',
        legendTooltipText: 'pcc power tooltip',
        unit: 'KW',
        resolution: 2
      }
    }, AddSeriesType.Initial);
  }

  private addBatterySeries() {
    this.service.addSeries(this.instanceId, {
      gridIndex: 1,
      identifier: 'battery-soc',
      properties: {
        name: 'Battery SOC',
        smooth: true,
        yAxisProperties: {
          gridIndex: 0,
          position: 'right',
          type: 'value',
          scale: false,
          name: '%',
          min: 0,
          max: 100
        },
        visible: true,
        showSymbol: true,
        symbol: SiSymbol.diamond,
        symbolSize: 3,
        lineWidth: 2,
        missingDataOpacity: 0.7,
        lineType: 'solid',
        lineColor: '#0856a1',
        unit: '%',
        legendTooltipText: 'Output of Battery',
        resolution: 2
      }
    }, AddSeriesType.Initial);
  }

  showProperty(rowDetails: any) {
    this.rowDetails = clone(rowDetails);
  }


}
