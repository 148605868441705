import { Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './pages/about/about.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard.component';
import { DrDashboardComponent } from './pages/dr-dashboard/dr-dashboard.component';
import { DrDemoComponent } from './pages/dr-demo/dr-demo.component';
import { LoginComponent } from './pages/login/login.component';
import { SingleSignOnComponent } from './pages/login/sso.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { EfficiencyWidgetComponent } from './widgets/ews/efficiency-widget/efficiency-widget.component';

export const routes: Routes = [
  {
    path: 'main', component: MainComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      { path: 'dashboard/:id', component: DashboardPageComponent },
      { path: 'onboarding/:key', component: OnboardingComponent },
      { path: 'dr-dashboard', component: DrDashboardComponent },
      { path: 'dr-demo', component: DrDemoComponent },
      { path: 'about', component: AboutComponent },
      { path: 'efficiency', component: EfficiencyWidgetComponent },
      { path: '', redirectTo: 'onboarding/???', pathMatch: 'full' },
      { path: '**', redirectTo: 'onboarding/???', pathMatch: 'full' }
    ]
  },
  // { path: 'invitation/landing', component: SiInvitationComponent, canActivate: [AuthGuard] },
  { path: 'sso', component: SingleSignOnComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: 'main', pathMatch: 'full' }
];
