<div class="container-fluid si-layout-fixed-height si-dashboard si-layout-content-padding sticky">
  <h2 class="page-top-title text-secondary mb-7 ng-star-inserted">Connect to Solace</h2>
  <form [formGroup]="form" class="d-flex h-100">
    <si-form-container
      #formContainer
      [form]="form"
      [enableValidationHelp]="true"
    >
      <div si-form-container-content>
        <div class="mb-6">
          <si-form-item label="URL" [labelWidth]="labelWidth" [required]="true">
            <input type="text" class="form-control" formControlName="url">
          </si-form-item>
          <si-form-item label="VPN" [labelWidth]="labelWidth" [required]="true">
            <input type="text" class="form-control" formControlName="vpn">
          </si-form-item>
<!--          <si-form-item label="User" [labelWidth]="labelWidth" [required]="true">-->
<!--            <input type="text" class="form-control" formControlName="user">-->
<!--          </si-form-item>-->
<!--          <si-form-item label="Password" [labelWidth]="labelWidth" [required]="true">-->
<!--            <input type="text" class="form-control" formControlName="password">-->
<!--          </si-form-item>-->
        </div>
        <div si-form-container-buttons>
          <button type="submit" (click)="onConnectButtonClicked()"
                  [disabled]="!form.valid || isConnected()" class="btn btn-primary me-2">Connect</button>
          <button type="submit" (click)="onDisconnectButtonClicked()"
                  [disabled]="!isConnected()" class="btn btn-primary me-2">Disconnect</button>
        </div>
      </div>
    </si-form-container>
  </form>
</div>
