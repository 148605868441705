<si-flexible-dashboard
  [dashboardId]="id"
  [heading]="title"
  [widgetCatalog]="widgetCatalog"
>
<app-device-selector
menubar
filters-slot
[building]="selectedBuilding"
>
</app-device-selector>
</si-flexible-dashboard>
