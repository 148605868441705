import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlSegmentGroup
} from '@angular/router';
import { AuthenticationService, SiProfileService } from '@building-x/common-ui-ng';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(AuthenticationService)
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(SiProfileService) private profileService: SiProfileService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const redirectUrl = document.head.getElementsByTagName('base')[0].href;

    return new Promise<boolean>((resolve, reject) => {
      this.authenticationService
        .init(
          environment.clientId,
          environment.issuer,
          redirectUrl,
          redirectUrl,
          environment.audience,
          environment.siemensIdBaseUrl
        )
        .then(fulfilled => {
          if (route.url[0].path === 'login') {
            resolve(true);
            return;
          }

          if (this.authenticationService.isAuthenticated()) {
            this.assertValidIdentity(resolve);
          } else {
            if (route.url[0].path === 'sso') {
              this.authenticationService.oAuthService
                .loadDiscoveryDocumentAndTryLogin()
                .then(done => resolve(done));
              return;
            }

            this.clearValidIdentity();

            const urlTree = this.router.parseUrl(state.url);
            const urlWithoutParams = urlTree.root.children.primary.segments
              .map(it => it.path)
              .join('/');

            const outlets = this.setOutlets(urlTree.root.children.primary.children);
            sessionStorage.setItem('entryPointUrl', urlWithoutParams);
            sessionStorage.setItem(
              'entryPointOutlets',
              JSON.stringify(outlets)
            );
            sessionStorage.setItem(
              'entryPointQueryParams',
              JSON.stringify(state.root.queryParams)
            );

            this.router.navigate(['login'], { relativeTo: this.route.root });
            resolve(false);
          }
        });
    });
  }

  private setOutlets(primaryChildren: { [key: string]: UrlSegmentGroup }) {
    const outlets: any = {};
    Object.keys(primaryChildren).forEach(key => {
      if (key !== 'numberOfChildren') {
        if (primaryChildren[key].segments.length) {
          outlets[key] = primaryChildren[key].segments[0].path;
        }
      }
    });
    return outlets;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }

  assertValidIdentity(resolve: any) {
    if (this.hasValidIdentity()) {
      resolve(true);
      return;
    }

    this.profileService.setIdentity().subscribe(
      () => {
        sessionStorage.setItem('identitySet', 'true');
        if (sessionStorage.getItem('entryPointUrl')) {
          const redirectUrl = sessionStorage.getItem('entryPointUrl');
          const redirectQueryParams = JSON.parse(
            sessionStorage.getItem('entryPointQueryParams') ?? ''
          );
          const outlets = JSON.parse(
            sessionStorage.getItem('entryPointOutlets') ?? ''
          );

          sessionStorage.removeItem('entryPointUrl');
          sessionStorage.removeItem('entryPointQueryParams');
          sessionStorage.removeItem('entryPointOutlets');
          this.router.navigate([redirectUrl, redirectQueryParams, { outlets }]);
        }
        resolve(true);
      },
      () => {
        resolve(true);
      }
    );
  }

  hasValidIdentity() {
    return sessionStorage.getItem('identitySet') === 'true';
  }

  clearValidIdentity() {
    sessionStorage.removeItem('identitySet');
  }

}