import { Component, Input, OnChanges } from '@angular/core';

import { Building } from '../../services/buildings.service';
import { ConfigService } from '../../services/config.service';
import { DeviceService } from '../../services/device.service';

@Component({
  selector: 'app-device-selector',
  templateUrl: './device-selector.component.html',
  styleUrls: ['./device-selector.component.scss']
})
export class DeviceSelectorComponent implements OnChanges {

  
  @Input() building!: Building;
  deviceArr: any[] = [];
  gatewayId!: string;

  trackByIndex = (index: number): number => index;

  constructor(
    private deviceService: DeviceService,
    private configService: ConfigService
  ) {}

  ngOnChanges() {
    if (this.building) {
      this.deviceService.getDeviceDetails(this.building?.partition, this.building?.id).subscribe(
        (deviceList: any[]) => {
          this.deviceArr = deviceList;
          if (this.deviceArr.length >= 1) {
            this.gatewayId = this.deviceArr[0]?.attributes?.activationKey;
            this.setConfig(this.gatewayId)
          } else {
            this.setConfig('');
          }
        }
      );
    }
  }

  onDeviceChange(event: any): void {
    this.setConfig(event?.target?.value);
  }

  setConfig(gatewayId: string): void {
    this.configService.setConfig({ site: this.building?.name, partitionId: this.building?.partition, gatewayId: gatewayId,
      siteId: this.building?.id, latitude: this.building?.latitude, longitude: this.building?.longitude });
  }

}
