import { Component } from '@angular/core';

import { environment } from '../environments/environment';
import { Localization } from './shared/services/localization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  cookieUrl = environment.cookieUrl;

  constructor(public localization: Localization) {
    localization.detectLanguage();
  }
}
