<si-chart *ngIf="!error"
  #chart
  (siResizeObserver)="chart.resize()"
  [options]=options
>
</si-chart>
<div *ngIf="initialized && error" class="wrapper">
  <si-unauthorized-page
    [subHeading] = "errorText"
    [description] = "errorDesc">
  </si-unauthorized-page>
</div>

