import { Component } from '@angular/core';
import { AboutData } from '@building-x/common-ui-ng';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html'
})
export class AboutComponent {
  aboutData: AboutData;

  constructor() {
    this.aboutData = {
      appIcon: '/assets/images/account.svg',
      appName: 'Energy Web Services',
      ossFilePath: '/assets/readme-oss.txt',
      version: '0.6.0'
    };
  }
}
