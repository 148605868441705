<div class="si-layout-fixed-height h-100">
  <si-navbar
    [appName]="'Energy Web Services'"
    [showSecondaryNavbar]="showSecondaryNavbar"
    [currentAppPermission]="'app.assetMgr'"
    [defaultHelpPortalURL]="'https://siemens-uat.fluidtopics.net/home'"
    [menuItems]="additionalMenuItem"
    [additionalInfoLinks]="additionalInfoLinks"
    [infoLinks]="{ items: [{ title: 'About', link: '/main/about' }]}" (logout)="onLogout($event)"
    [showMyAccountLink]="true"
    [openCustomerSwitcher]="collapseCustomerSwitcher"
    (customerSelection)="customerSelection($event)"
    [appSwitcherDataSource]="appSwitcherDataSource"
    [accountManagerUrl]="'https://account.apps.horizondev.cloud/#/sso/myprofile'"
    [enableOfflinePage]=true
    (orgSelection)="onOrgChange($event)"
    [updateNavbarValues]="updateOrganization"
    [resetNavbar]="resetNavbar"
    [allowPartitionFilter]="true"
    [languages]="languages"
    [persistCustomerId]="persistCustomerId">
  </si-navbar>
  <si-navbar-vertical [items]="secondaryNavbarLinks" navbarCollapseButtonText="collapse"
                      navbarExpandButtonText="expand">
    <div class="px-9 pt-6">
      <si-breadcrumb *ngIf="showBreadCrumb" [items]="breadCrumbItems"></si-breadcrumb>
    </div>
    <div class="si-layout-fixed-height">
      <router-outlet></router-outlet>
    </div>
  </si-navbar-vertical>
</div>
<si-toast-notification-drawer></si-toast-notification-drawer>

<si-side-panel
  #sidePanel
  [collapsed]="collapsed"
  [mode]="'scroll'"
  (closed)="closeCustomerSwitcher()"
  [size]="'regular'"
>
</si-side-panel>
